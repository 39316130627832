/**
 * Bookmark
 */
.btn--bookmark {
  display: flex;
  align-items: center;
  line-height: 1;
  transition: transform .2s;
  color: #ccc;
  &:hover {
    transform: scale(1.2);
  }
  &.is-bookmarked {
    color: $green;
  }
}

/**
 * Search
 */
#search {
  &::placeholder {
    color: #bbb;
    font-size: .9rem;
  }
}

/**
 * Post list
 */
.post-list__item {
  padding-bottom: 10px;
  border-bottom: 1px dashed #ddd;
  margin-bottom: 10px;
  .post-meta {
    opacity: .5;
    transition: opacity .2s ease-out;
    // Mobile
    @media only screen and (max-width: 500px) {
      display: block;
      > div:first-child {
        margin-bottom: 10px;
      }
    }
  }
  &:hover {
    .post-meta {
      opacity: 1;
    }
  }
}

/**
 * Single post
 */
.post-content {
  margin-bottom: 20px;
}
.markdown-content {
  word-break: break-word;

  h1, h2, h3 {
    font-weight: bold;
    margin: 0 0 10px;
  }
  h1 { font-size: 25px; }
  h2 { font-size: 18px; }
  h3 { font-size: 16px; }
  hr { border-top: 1px dashed #ddd; }
  code {
    display: inline-block;
    background: #f9f9f9;
    padding: 1px 5px;
    border: 1px solid #eee;
    border-radius: 3px;
  }
}

.post-media {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-gap: 20px;
  margin: 0 0 20px;
  padding: 20px 0;
  border: 1px solid #ddd;
  border-width: 1px 0;
  .media {
    display: block;
    padding: 0px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    &:last-child {
      margin-right: 0;
    }
    &--link {
      display: flex;
      align-items: center;
      padding: 0 10px;
    }
  }
}
.comment {
  padding: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  position: relative;
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 2px;

  &__meta {
    margin-bottom: 10px;
  }
  &__content {
    position: relative;
    padding-right: 40px;
    p {
      margin: 0 0 10px;
    }
    *:last-child { margin-bottom: 0; }
  }
  &__reply {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.subcomments {
  padding-left: 8px;
  border-left: 3px solid #aea;
}

.subcomments .comment {
  padding: 10px;
}
.subcomments .comment:before {
  content: "\0020";
  display: block;
  position: absolute;
  top: 11px;
  left: -9px;
  border: 6px solid transparent;
  border-left-color: #aea;
}

.subcomments-odd {
  border-color: #9edaff;
}
.subcomments-odd > div > .comment:before {
  border-left-color: #9edaff;
}

.col { padding: 0; margin-bottom: 20px; }

@media only screen and (min-width: 500px) {
  .row {
    display: flex;
    margin-bottom: 20px;
  }
  .col {
    flex: 1;
  }
  .col:nth-last-of-type(even) {
    padding: 0 15px 0 0;
  }
  .col:nth-last-of-type(odd) {
    padding: 0 0 0 15px;
  }
}


/**
 * Profile
 */
.profile {
  &__img {
    border: 1px solid #999;
    border-radius: 4px;
    // box-shadow: 0 0 5px #333;
  }
}


/**
 * Login
 */
.FormError {
  padding: 5px;
  font-size: 12px;
  color: #a94442;
}


/**
 * Message
 */
.message {
  margin: 0 0 20px;
  &--me {
    .message__text {
      background: #d3eeff;
      border-color: #abdfff;
    }
  }
  &__text {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px;
    background: #f6f9fa;
    display: inline-block;
  }
  &__meta {
    padding: 2px 5px 0;
  }
  &__unread {
    background: #e6f0ff;
    td {
      border-left-color: #91c7ff;
      border-right-color: #91c7ff;
      &:first-child { border-left-width: 3px; }
      &:nth-of-type(2) { font-weight: 500; }
      &:last-child { border-right-width: 3px; }
    }
  }
  &__list {
    td:first-child { max-width: 260px; }
    .d--date { color: #333; }
  }
}


/**
 * Quarantine map
 */
.quarantine-map {
  height: 65vh;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  border-radius: 5px;
  // background: url(/map.png);
  // background-size: cover;
}
.lockdown {
  align-items: center;
  &__text { width: 45%; }
  &__form { width: 45%; }
  &__group {
    display: flex;
    .input {
      border-radius: 3px 0 0 3px;
      border-right: 0;
    }
    .btn { border-radius: 0 3px 3px 0; }
  }
  @media (max-width: 800px) {
    > * { width: 100%; }
    &__text {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ddd;
    }
  }
}

