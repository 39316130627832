.float-right { float: right }
.float-left { float: left }

.flex-end {
  align-items: flex-end;
}
.space-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.center-items {
  display: flex;
  align-items: center;
}

.block {
  margin: 0 0 20px;
}


/**
 * Typography
 */
 .desc {
  font-size: .8rem;
  color: #777;
}

.meta {
  font-size: .8rem;
  color: #999;
  letter-spacing: 1px;
}
// .meta a {
//   font-weight: bold;
//   color: #aaa;
// }

.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}


/**
 * Colors
 */
.green { color: $green; }
