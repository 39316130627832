label {
  display: block;
  margin: 0 0 10px;
  font-weight: bold;
}
textarea,
select,
.input {
  width: 100%;
  padding: 7px 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #444;
}
select { height: 34px; }
textarea {
  min-height: 150px;
  display: block;
}
.textarea--small {
  min-height: 95px;
}
.form-group {
  margin: 0 0 20px;
}

.input-btn {
  display: flex;
  & div:first-child,
  & input:first-child {
    flex: 1;
  }
  .input {
    border-right-width: 0;
    border-radius: .25rem 0 0 .25rem;
  }
  .btn {
    border-radius: 0 .25rem .25rem 0;
  }
}
.input-clear {
  position: relative;
  display: flex;
  .clear {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: 0;
    font-size: .8rem;
    opacity: .2;
    cursor: pointer;
    transition: opacity .3s ease-in;
    &:hover {
      opacity: .7;
    }
  }
}


.downshift {
  &__input {
    position: relative;
  }
  &__clear {
    position: absolute;
    top: 0px;
    right: 0px;
    border: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    background: none;
    width: 34px;
    height: 34px;
    color: #444;
    font-size: 14px;
    cursor: pointer;
  }
  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 250px;
    overflow: scroll;
    z-index: 100;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 3px;
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      padding: 5px 10px;
      &:hover {
        background: #f5f5f5;
        cursor: pointer;
      }
    }
  }


  &__multi {
    &__item {
      position: relative;
      display: inline-block;
      background: $lightPrimaryBg;
      border: 1px solid $lightPrimaryBorder;
      padding: 3px 7px;
      border-radius: 3px;
      margin: 0 10px 10px 0;
      white-space: nowrap;
      font: inherit;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background: #f9f9f9;
        border-color: #ccc;
      }
      &:hover::after,
      &:hover::before {
        opacity: 1;
      }
      &::after,
      &::before {
        pointer-events: none;
        opacity: 0;
        display: block;
        position: absolute;
        left: 50%;
        background: $lightPrimaryBg;
        border: 1px solid $lightPrimaryBorder;
        transition: opacity 0.3s ease-out;
      }
      &::after {
        content: "";
        bottom: calc(110% - 3px);
        transform: translateX(-50%) rotate(45deg);
        width: 6px;
        height: 6px;
        border-width: 0 1px 1px 0;
      }
      &::before {
        content: attr(title);
        bottom: 110%;
        transform: translateX(-50%);
        padding: 2px 5px;
        font-size: 12px;
        border-radius: 3px;
      }
    }
  }
}
