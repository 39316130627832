.btn {
  position: relative;
  background: #007ac3; // #0086DB;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #0069ab; // #006db1;
  padding: .5rem .7rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: opacity .3s;
  font-weight: normal;
  transition: background .2s ease-in;
  &:hover {
    color: #f0f9ff;
    background: #00629b;
  }

  &--block {
    display: block;
  }
  &--small {
    font-size: 0.75rem;
    padding: .2rem .4rem;
  }
  &--warn {
    background-color: #999;
  }
  &--danger {
    background: #FF595E;
  }
  &--secondary {
    background: none;
    color: #005c96;
    text-shadow: none;
    border: 1px solid #0086DB;
    transition: all .3s;
    &:hover {
      background: #0086DB;
      color: #fff;
      border-color: #0086DB;
    }
    // color: #337d54;
    // border: 1px solid #61a980;
    // &:hover {
    //   background: #337d54;
    //   border-color: #337d54;
    // }
  }

  &--link {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: #02667d;
    cursor: pointer;
    &--desc {
      color: #999;
    }
  }

  &--header {
    border: 1px solid #fff;
    transition: all .2s;
    &:hover {
      text-shadow: 0 0 2px darken(#0086DB, 20%), 0 0 1px darken(#0086DB, 20%);
      background: rgba(255, 255, 255, .3);
      border-color: rgba(255, 255, 255, .7);
    }
  }
}

.btn:disabled {
  opacity: 0.3;
  cursor: wait;
}
