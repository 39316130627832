* { box-sizing: border-box; }
html, body { background: #f3f6f7; }
body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  color: #444;
}

h1 {
  font-size: 1.9rem;
  font-weight: 300;
  color: #555;
  margin: 0 0 20px;
  line-height: 1.1;
  letter-spacing: 1px;
  word-break: break-word;
}
@media (max-width: 400px) {
  .h1--long { font-size: 1.5rem; }
}
.subtitle {
  margin: -15px 0 20px;
  color: #777;
}
h2 {
  font-weight: 400;
  font-size: 18px;
  margin: 0 0 10px;
}
h3 {
  font-weight: 400;
  font-size: 18px;
  margin: 0 0 5px;
}
h4 {
  font-size: 1rem;
  margin: 0 0 5px;
}
a {
  color: #02667d;
  text-decoration: none;
  &:hover {
    color: #222;
  }
}

p {
  margin: 0 0 20px;
}

small, .small {
  font-size: 0.8rem;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid #ddd;
  margin: 0 0 20px;
}


.list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  li {
    margin: 0;
    &.active > a {
      color: #222;
      font-weight: bold;
    }
  }
  a {
    display: block;
    padding: 2px;
  }
  &--lined {
    border-top: 1px solid #eee;
    li {
      padding: 3px 2px;
      border-bottom: 1px solid #eee;
    }
  }
}


/**
 * Tables
 */
table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.table {
  margin: 0 0 20px;
  td, th {
    padding: 5px 7px;
  }
  &--striped {
    td, th {
      border: 1px solid #ddd;
    }
    thead > tr:nth-of-type(2n + 1),
    tbody > tr:nth-of-type(2n) {
      background: #f9f9f9;
    }
  }
}
