img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0;
}

.uploader {
  border: 1px solid #ddd;
  border-radius: 3px;
  background: #fff;
  margin-bottom: 20px;


  &--can-drop {
    border: 1px solid #0bcc2b;
    box-shadow: 0 0 7px 1px #0bcc2b;
  }
  &--can-not-drop {
    border: 1px solid #cc280b;
    box-shadow: 0 0 7px 1px #cc280b;
  }
  &__desc {
    text-align: center;
    padding: 60px 20px;
    color: #777;
    cursor: pointer;
    span {
      display: inline-block;
      padding: 0 4px 1px;
      background: #e5e5e5;
      border-radius: 3px;
    }
  }
  &--has-files &__desc {
    padding: 20px;
  }
  &__files {
    border-top: 1px solid #ddd;
    margin: 0 20px 20px;
    padding: 20px 0 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  &__close {
    background: #fff;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    line-height: 0.9;
    color: #d80000;
    font-size: 20px;
    display: block;
    padding: 0;
    border: 2px solid #fff;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      transform: scale(1.3);
      box-shadow: 0 0 7px 1px #d80000;
    }
  }
  &__file {
    position: relative;
    width: 100px;
    border-radius: 5px;
    > div {
      position: relative;
    }
    &__loading {
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      background: rgba(0, 0, 0, .4);
      border-radius: 5px;
      &::after {
        display: block;
        content: "\0020";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
        border: 5px solid #0086DB;
        border-color: #0086DB #fff;
        border-radius: 50%;
        // opacity: .7;
        animation: uploadingIcon 1.5s linear infinite;
      }
    }
  }
}

@keyframes uploadingIcon {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}
