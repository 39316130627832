
.loading {
  text-align: center;
  padding: 30px;
}

.loading-text {
  font-size: 12px;
  animation: loadingText 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
}

.loading-error-icon {
  font-size: 112px;
  color: #FFB493;
}

.loading-icon {
  width: 70px;
  height: 70px;
  margin: 0 auto 30px;
  border: 4px solid #0086DB;
  border-radius: 100%;
  border-top-color: #f0f6ff;
  border-bottom-color: #f0f6ff;
  animation: loadingIcon 1s linear infinite;
}
@keyframes loadingIcon {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-webkit-keyframes loadingText {
  0% { opacity: 0.0; }
  50% { opacity: 1.0; }
  100% { opacity: 0.0; }
}
